<template>
    <div class="information">
        <login-header :type="type"></login-header>
        <div class="information-center">
            <transition name="el-zoom-in-top">
                <div v-if="informationShow">
                    <img class="information-center-logo" :src="url" alt="">
                    <div class="information-center-text">
                        <div style="margin-bottom:10px">完善资料可以让更多异性快速关注到您！</div>
                        <div>是否前往完善？</div>
                    </div>
                    <div style="margin-bottom:2vh" class="information-center-botton">{{$t('login.information')}}</div>
                    <div @click="goHome" class="information-center-botton">{{$t('login.gogo')}}</div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import LoginHeader from "../../components/loginHeader.vue"
import userFormList from '../../components/say.vue'
export default {
    components: { LoginHeader, userFormList },
    data() {
        return{
            type: "information",
            informationShow: false,
            url: require("../../assets/images/logo.png"),
        }
    },
    mounted(){
        setTimeout(()=>{
            this.informationShow = true
        },2000)
    },
    methods: {
        goHome(){
            this.$router.push({
                path: "/page/home"
            })
        }
    }
}
</script>

<style scoped>
.information{
    width: 100vw;
    height: 100vh;
    background: url("../../assets/images/information_bj.jpg") no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 1000px;
    z-index:-10;
    zoom: 1;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    animation: myfirst 1s;
	-webkit-animation: myfirst 1s;
    display: flex;
    justify-content: flex-end
}
@keyframes myfirst{
    from {
        -webkit-filter: blur(30px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
        filter: blur(30px);
    }
    to {
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -o-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);
    }
}
@-webkit-keyframes myfirst{
	from {
        -webkit-filter: blur(30px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
        filter: blur(30px);
    }
    to {
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        -o-filter: blur(0);
        -ms-filter: blur(0);
        filter: blur(0);
    }
}
.information-center{
    height: 100vh;
    background: linear-gradient(100deg, transparent 30vh, rgba(255, 170, 0, .8) 0) top left;
    /* background: linear-gradient(100deg, transparent 30vh, rgba(0, 0, 0, .7) 0) top left; */
    animation: myleft .5s 1s forwards;
	-webkit-animation: myleft .5s 1s forwards;
    display: flex;
    justify-content: flex-end;
    padding-right: 18.5vw;
    flex-direction: row;
    align-items: center;
}
@keyframes myleft{
    from {
        width: 0;
    }
    to {
        width: 50vw
    }
}
@-webkit-keyframes myleft{
	from {
        width: 0;
    }
    to {
        width: 50vw
    }
}
.information-center>div{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end; 
    width: max-content;
    height: max-content;
    margin-bottom: 5vh
}
.information-center-botton{
    width: max-content;
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
    font-size: 12px;
    padding: 0 50px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, .8);
    cursor: pointer;
    margin-right: 22px
}
.information-center-botton:hover{
    background: #fff;
    color: rgba(255, 170, 0, .8)
}
.information-center-text{
    text-align: right;
    font-size: 26px;
    font-weight: 100;
    color: #fff;
    margin-bottom: 6vh;
    padding-right: 5px
}
.information-center-logo{
    width: 300px;
    height: 64px;
    object-fit: scale-down;
    margin-bottom: 5vh
}
</style>