<template>
    <div class="login-header">
        <div class="login-header-center"></div>
        <div @click="langShow = true">
            <span>{{langItem}}</span>
            <i class="iconfont icon-jiantouxia"></i>(Language)
            <transition name="el-zoom-in-top">
                <div class="login-lang" v-if="langShow">
                    <div v-for="(item,index) in langList" :key="index" @click.stop="goLang(item)">{{item.name}}</div>
                </div>
            </transition>
        </div>
        <div></div>
        <div v-if="type == 'falv'">
            <span @click="goLogin" style="margin-right:20px">{{$t('login.logingo')}}</span>
            <span @click="goRegister">{{$t('login.confirm')}}</span>
        </div>
        <div v-else>
            <div v-if="type == 'login'" @click="goRegister">{{$t('login.register')}}</div>
            <div v-else-if="type == 'register'" @click="goLogin">{{$t('login.login')}}</div>
            <div v-else>{{$t('login.welcome')}}，吴三桂</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type"],
    data(){
        return{
            langItem: "繁体中文",
            langShow: false,
            langList: [{
                name: "简体中文",
                value: "zh-cn"
            },{
                name: "繁体中文",
                value: "zh-tw"
            },{
                name: "English",
                value: "en-us"
            }],
        }
    },
    created(){
        if(sessionStorage.lang){
            this.$i18n.locale = sessionStorage.lang
            this.langList.forEach( item => {
                if(item.value == sessionStorage.lang){
                    this.langItem = item.name
                }
            })
        }else{
            this.$i18n.locale = 'zh-tw'
            this.langItem = '繁体中文'
        }
    },
    methods: {
        goContent(num){
            sessionStorage['number'] = num
            this.$router.push({
                path: "/loginContent"
            })
        },
        goLang(item){
            this.langItem = item.name
            this.$i18n.locale = item.value
            sessionStorage['lang'] = item.value
            this.langShow = false
        },
        goRegister(){
            this.$router.push({
                path: "/register"
            })
        },
        goLogin(){
            this.$router.push({
                path: "/"
            })
        }
    }
}
</script>

<style scoped>
.login-header-center{
    display: flex;
    margin-right: 30px
}
.login-header-center>div{
    width: max-content;
    padding: 0 15px;
    border-right: 1px solid #ccc;
    font-size: 14px;
    cursor: pointer;
    color: #999;
}
.login-header-center>div:last-child{
    border: none
}
.login-header-center>div:hover{
    color: #000;
}
.login-header{
    height: 30px;
    line-height: 30px;
    background: rgba(255, 255, 255, .8);
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20vw
}
.login-header>div:nth-child(2){
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, .6);
    position: relative;
}
.login-lang{
    position: absolute;
    right: 0;
    top: 30px;
    background: rgba(255, 255, 255, .8);
    width: 300px;
    height: max-content;
    display: flex;
    padding: 10px 20px 0 20px;
    cursor: default;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, .1);
}
.login-lang>div{
    width: max-content;
    margin: 0 20px 10px 0;
    font-size: 14px;
    height: max-content;
    cursor: pointer;
}
.login-lang>div:hover{
    color: #f00
}
.login-header>div:nth-child(3){
    height: 14px;
    width: 1px;
    background: rgba(0, 0, 0, .2);
    margin: 0 20px;
}
.login-header>div:nth-child(4){
    font-size: 14px;
    cursor: pointer;
    color: rgba(0, 0, 0, .6);
}
.login-header>div:nth-child(4):hover{
    color: #f00;
}
</style>
